<script setup lang="ts">
</script>

<template>
  <a
    class="mx-2 rounded border bg-white py-1 px-3 text-sm"
  >
    <slot />
  </a>
</template>
