import { serialize } from 'object-to-formdata';
import api from './index';
import decamelizeKeys from '../utils/decamelize-for-files';
export const MIN_PASSWORD_LENGTH = 8;
export default { login(rut, password, remember) { const path = '/usuarios/sign_in'; return api({ method: 'post', url: path, data: decamelizeKeys({ user: { rut, password, rememberMe: remember } }) }); }, register(user) { const path = '/usuarios'; return api({ method: 'post', url: path, data: decamelizeKeys({ user }) }); }, forgotPassword(rut) { const path = '/usuarios/password'; return api({ method: 'post', url: path, data: decamelizeKeys({ user: { rut } }) }); }, changePassword(user) { const path = '/usuarios/password'; return api({ method: 'put', url: path, data: decamelizeKeys({ user }) }); }, update(data) { const path = '/api/internal/users/me'; let requestData = {}; if (data.applicationId) {
        requestData = { applicationId: data.applicationId };
    }
    else {
        requestData = { user: data };
    } return api({ method: 'put', headers: { 'Content-Type': 'multipart/form-data' }, url: path, data: serialize(decamelizeKeys({ ...requestData })) }); }, sendApplicationIdEmail() { const path = '/api/internal/users/me/send_application_id_email'; return api({ method: 'post', url: path }); }, logout() { const path = '/usuarios/sign_out'; return api({ method: 'delete', url: path }); } };
