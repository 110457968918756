/* eslint-disable max-statements */ import { createApp, provide, readonly, ref, onMounted, watch } from 'vue';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { createI18n } from 'vue-i18n';
import { VueQueryPlugin } from '@tanstack/vue-query';
import Rails from '@rails/ujs';
import InlineSvg from 'vue-inline-svg';
import './css/application.css';
import IndividualApplicationForm from './components/individual-application-form.vue';
import IndividualApplicationTracker from './components/individual-application-tracker.vue';
import IndividualApplicationTrackerFinder from './components/individual-application-tracker-finder.vue';
import TheNavBar from './components/the-nav-bar.vue';
import TheNavSidebar from './components/the-nav-sidebar.vue';
import TheContactFormModal from './components/the-contact-form-modal.vue';
import BaseModal from './components/base-modal.vue';
import BaseLoading from './components/base-loading.vue';
import BaseLabel from './components/base-label.vue';
import BaseInput from './components/base-input.vue';
import BaseSelect from './components/base-select.vue';
import BaseMultiselect from './components/base-multiselect.vue';
import BaseButton from './components/base-button.vue';
import BaseFileInput from './components/base-file-input.vue';
import BaseRadio from './components/base-radio.vue';
import BaseCheckbox from './components/base-checkbox.vue';
import BaseTag from './components/base-tag.vue';
import BaseTable from './components/base-table.vue';
import BaseTableButton from './components/base-table-button.vue';
import BaseForm from './components/base-form.vue';
import EventItem from './components/event-item.vue';
import FeedbackBanner from './components/feedback-banner.vue';
import CourseList from './components/course-list.vue';
import FeedbackList from './components/feedback-list.vue';
import CourseApplicationForm from './components/course-application-form.vue';
import ApplicationExpensesReportForm from './components/application-expenses-report-form.vue';
import UserSession from './components/user-session.vue';
import UserProfile from './components/user-profile.vue';
import UserMigrationModal from './components/user-migration-modal.vue';
import MedicalHoursForm from './components/medical-hours-form.vue';
import UserResidencyValidationForm from './components/user-residency-validation-form.vue';
import LanguageDropdown from './components/language-dropdown.vue';
import HeroSlider from './components/hero-slider.vue';
import esCL from './locales/es-CL.json';
import en from './locales/en.json';
import { useWindowScroll } from '@vueuse/core'; // eslint-disable-next-line max-statements
document.addEventListener('DOMContentLoaded', () => {
    Rails.start();
    const i18n = createI18n({ legacy: false, locale: window.siteVariables.locale, messages: { 'es-CL': esCL, 'en': en } });
    const app = createApp({ components: { TheNavBar, TheNavSidebar, IndividualApplicationForm, IndividualApplicationTracker, IndividualApplicationTrackerFinder, EventItem, FeedbackBanner, CourseList, FeedbackList, TheContactFormModal, CourseApplicationForm, ApplicationExpensesReportForm, UserProfile, UserMigrationModal, MedicalHoursForm, UserResidencyValidationForm, LanguageDropdown, HeroSlider }, setup() {
            function menuItemIsActive(hrefs) { const matches = hrefs.filter(href => window.location.pathname.startsWith(href) || window.location.hash.startsWith(href)); return matches.length > 0; }
            const t = i18n.global.t;
            const menuItems = ref([{ label: t('navigation.menu.alliance'), href: '/#alianza', active: menuItemIsActive(['alianza']) }, { label: t('navigation.menu.programs'), href: '/#programas', active: menuItemIsActive(['programas']) }, { label: t('navigation.menu.fai'), href: '/programas/fai', active: menuItemIsActive(['/programas/fai']) }, { label: t('navigation.menu.experiences'), href: '/#experiencias', active: menuItemIsActive(['experiencias']) }, { label: t('navigation.menu.events'), href: '/eventos', active: menuItemIsActive(['/eventos']) }, { label: t('navigation.menu.links'), href: '/enlaces', active: menuItemIsActive(['enlaces']) }]);
            provide('menuItems', readonly(menuItems));
            const isRoot = ref(window.location.pathname === '/');
            onMounted(() => {
                const { y } = useWindowScroll();
                const previousScroll = ref(y.value);
                const scrollDirection = ref('down');
                const headerHeight = document.querySelector('#header')?.clientHeight ?? 0;
                document.querySelector('#vue-app').style.marginTop = `${headerHeight}px`;
                if (isRoot.value) {
                    const activeSectionEntry = ref();
                    const activeSectionObserver = new IntersectionObserver(entries => { scrollDirection.value = y.value > previousScroll.value ? 'down' : 'up'; previousScroll.value = y.value; const oldMenuItemIndex = menuItems.value.findIndex(item => item.href === `/#${activeSectionEntry.value?.target.id}`); const menuItemIndex = menuItems.value.findIndex(item => item.href === `/#${entries[0].target.id}`); if (scrollDirection.value === 'down') {
                        if (menuItemIndex > oldMenuItemIndex) {
                            activeSectionEntry.value = entries[0];
                        }
                    }
                    else {
                        if (menuItemIndex < oldMenuItemIndex) {
                            activeSectionEntry.value = entries[0];
                        }
                    } }, {
                        threshold: [...Array(10).keys()].map(x => x / 10)
                    });
                    watch(activeSectionEntry, entry => { if (entry) {
                        menuItems.value.forEach(item => { item.active = item.href === `/#${entry.target.id}`; });
                    }
                    else {
                        menuItems.value.forEach(item => { item.active = false; });
                        menuItems.value[0].active = true;
                    } }, { immediate: true });
                    menuItems.value.forEach(item => { const sectionId = item.href.replace('/', ''); const section = document.querySelector(sectionId); if (section) {
                        activeSectionObserver.observe(section);
                    } });
                }
            });
            const isMenuOpen = ref(false);
            provide('menuOpen', isMenuOpen);
            provide('toggleMenu', () => { isMenuOpen.value = !isMenuOpen.value; });
            const isContactFormModalOpen = ref(false);
            provide('toggleContactFormModal', () => { isContactFormModalOpen.value = !isContactFormModalOpen.value; });
            const PHONE_NUMBER_LENGTH = 8;
            provide('phoneNumberLength', PHONE_NUMBER_LENGTH);
            return { isContactFormModalOpen, isMenuOpen };
        } });
    app.use(i18n);
    app.use(VueQueryPlugin);
    app.component('VField', Field);
    app.component('VForm', Form);
    app.component('VErrorMessage', ErrorMessage);
    app.component('InlineSvg', InlineSvg);
    app.component('BaseModal', BaseModal);
    app.component('BaseLoading', BaseLoading);
    app.component('BaseLabel', BaseLabel);
    app.component('BaseInput', BaseInput);
    app.component('BaseSelect', BaseSelect);
    app.component('BaseMultiselect', BaseMultiselect);
    app.component('BaseButton', BaseButton);
    app.component('BaseFileInput', BaseFileInput);
    app.component('BaseRadio', BaseRadio);
    app.component('BaseCheckbox', BaseCheckbox);
    app.component('BaseTag', BaseTag);
    app.component('BaseTable', BaseTable);
    app.component('BaseTableButton', BaseTableButton);
    app.component('BaseForm', BaseForm);
    app.component('UserSession', UserSession);
    app.mount('#vue-app');
    return app;
});
